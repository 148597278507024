/** Very top navbar */

.navbar.bg-light {
    padding: 0.25em;
    font-size: small;
    background: #F7F7F7;
}

.navbar.bg-light a {
    padding: 0.25rem;
    color: #6C757D;
}

/** Main navbar (logo + menu) */

nav.customer {
    padding: 0;
    background-color: #242D3D;
    min-height: 86px;
}

nav.customer .container, nav.customer .container #main-nav{
    align-items: stretch;
}


@media (max-width: $tablet){
    nav.customer .container{
        align-items: baseline;
    }
}
@media (max-width: 576px){
    nav.customer .container{
        padding: 0 0.75rem;
    }
}

nav.customer a.navbar-brand {
    padding: 15px 0 0 0;
    height: 87px;
}

nav.customer a.navbar-brand img {
    width: 10rem;
}

nav.customer .navbar-nav .nav-item
{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: $tablet){
    nav.customer .navbar-nav .nav-item
        {
        text-align: end;
        border: 0px !important;
        padding-right: 0px;
        }
    nav.customer #main-nav
    {
        margin-bottom: 1rem;
        margin-top: -1rem;
    }
}

nav.customer .nav-item {
    text-align: center;
    text-transform: uppercase;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: 300;
    border-left: solid 1px #666C77;
}

nav.customer .navbar-nav .nav-link {
    color:#fff;
}

nav.customer .nav-item:last-child {
    border-right: solid 1px #666C77;
}

nav.customer .customer-study {
    border-bottom: solid 4px #f5990c;
}
nav.customer .customer-research {
    border-bottom: solid 4px #eb1f00;
}
nav.customer .customer-community {
    border-bottom: solid 4px #a0a0a0;
}

/** Footer **/

footer.customer {
    font-size: small;
    color: #fff;
    padding: 1rem;
}

footer.customer a {
    color: #fff;
}

footer.customer h1 {
    font-size: medium;
}

footer.customer ul,
footer.customer p {
    margin-left: 1rem;
}

footer.customer ul.social li {
    margin-left: 1rem;
    margin-right: 1rem;
}

/** Search form */

.jumbotron {
    background: #fff;
    background-size: cover;
    color: #242D3D;
    min-height: 330px;
    display: flex;
}

.jumbotron .container
{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.jumbotron .container form{
    width: 100%;
}

    
@media (max-width: $tablet) {
    .jumbotron {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 180px;
        background: #fff;
    }
}

.jumbotron .input-group {
    border-bottom: solid 1px #242D3D;
}

.jumbotron input,
.jumbotron input:focus,
.jumbotron .input-group-text {
    box-shadow: none;
    border: none;
    background-color: transparent;
    color: #242D3D;
    font-size: xx-large;
    font-family: 'Open Sans';
    font-weight: 300;
}

.jumbotron button {
    background-color: #242D3D;
    border: none;
}

.jumbotron .list-inline-item a {
    color: #242D3D;
}

.jumbotron small.float-left {
    color: #242D3D;
    font-style: italic;
    font-size: x-small;
}

.jumbotron a.disabled {
    text-decoration: none;
    cursor: default;
    color: #333;
}

.jumbotron a.disabled,
.jumbotron a.disabled:hover,
.jumbotron a.disabled:active,
.jumbotron a.disabled:focus {
    text-decoration: none;
}

/** Tabs */

nav.tabs {
    margin-top: -2.51em;
}

nav.tabs .row {
    margin: auto;
}

ul.nav-tabs {
    width: 100%;
    border-bottom: none;
}

ul.nav-tabs li.nav-item {
    border-bottom: 1px solid #F7F7F7;
    }

ul.nav-tabs li.nav-item a {
    color: white;
    border: solid 1px rgba(36, 45, 61, 0.7);
    border-bottom: none;
    background-color: rgba(36, 45, 61, 0.5);
    font-weight: 600; 
    font-size: 0.95em;
    padding: 0.45em;
}
@media (max-width: 992px) {
    ul.nav-tabs li.nav-item a {
        font-size: 0.9em;
    }

    nav.tabs {
        margin-top: 1em;
    }
}   

section .btn-light
{
    background: #fff;
}

ul.nav-tabs li.nav-item .search-facet-count {
    font-weight: initial;
}

ul.nav-tabs li.nav-item a:hover,
ul.nav-tabs li.nav-item a:active,
ul.nav-tabs li.nav-item a:focus {
    border-color: rgba(36, 45, 61, 1);
    background-color: rgba(36, 45, 61, 1);
}

ul.nav-tabs li.nav-item a.active {
    border-color: rgba(0, 0, 0, .125);
    background-color: #F7F7F7;
}

ul.nav-tabs li.nav-item a.disabled {
    color: #999;
}

@media (max-width: 992px) {
    /* Stack the tabs on small screens */
    ul.nav-tabs,
    li.nav-item {
        display: inline-block;
        width: auto;
    }

    ul.nav-tabs li.nav-item a
    {
        color: #242D3D;
        border: 1px solid #dee2e6;
        background: #fff;
        border-radius: 0.25em;
    }
    ul.nav-tabs li.nav-item a.active, ul.nav-tabs li.nav-item a:hover
    {
        color: #fff;
        border: 1px solid #242D3D;
        background: #242D3Dff;
        border-radius: 0.25em;
    }
}

/** Search results */

section.search-results,
section.search-history,
section.search-cart {
    background-color: #fff;
}

section.search-results .container,
section.search-history .container,
section.search-cart .container{
    // padding-top: 3rem;
}

@media (max-width: 992px){
    section.search-results .container,
    section.search-history .container,
    section.search-cart .container{
        padding-top: 0rem;
    }

    .navbar-toggler.mb-3 {
        background: #242D3D;
        padding: 0.75rem 1rem 0.5em;
        color: #fff;
    }
    .navbar-toggler.mb-3 .navbar-toggler-icon {
        width: 1.25em;
        height: 1.25em;
    }
   
}

@media (max-width: 768px) {
    /* Hide sort / limit controls on small screens */
    .search-results .dropdown {
        display: none;
    }
}

@media screen and (min-width: $desktop){
    section.search-results {
        padding-top: 55px;
    }
}

/** Facets */

.card.search-facet 
{
    border-radius: 0px;
    margin-top: -1px;
}

.search-facets .card.search-refine {
    border-bottom: none;
}
.search-facets h3 {
    font-size: initial;
}


.search-facets .search-facet li {
    clear: right;
}

.search-facets .badge-default {
    color: #636c72; /* == text-muted */
    background-color: #eeefea;
}

.bg-dark
{
    background: #242D3D !important;
}

/** Results */

.search-results .search-tier h3 {
    font-size: x-large;
}

.search-results .card-header,
.search-results .card-footer {
    background-color: #DDDDDD;
}

.search-results .card-text img {
    max-height: 100px;
    max-width: 200px;
}

.search-results .card-footer strong:not(:first-child) {
    margin-left: 1rem;
}

.search-results .search-spelling {
    font-size: x-large;
}

/** Results - Courses */

.search-result-course .row h5 {
    color: #242D3D;
}

.search-result-course a.border-secondary {
    border-color: #ccc !important;
}

/** Results - People */

.search-result-people h4 a {
    color: #242D3D;
}

@media (max-width: $tablet) {
    .search-result-people img {
        margin-top: 1rem;
        width: 25%;
    }
}

.search-result-people .fa-user {
    font-size: 600%;
    color: #d7d8d2;
}

/** Results - Video */

.search-result-video h4 a {
    color: #242D3D;
}

.search-result-video .col-md-3 a {
    position: relative;
    display: block;
}

.search-result-video .card-img-overlay .badge {
    position: absolute;
    right: .5rem;
    bottom: .5rem;
}

.search-result-video .card-img-overlay .fa-play-circle-o {
    top: 30%;
    left: 38%;
    position: absolute;
    color: rgba(255, 255, 255, .75);
}

.search-result-video a:hover .card-img-overlay .fa-play-circle-o {
    color: white;
}

/** Results - Events */

.search-result-event .search-event-date {
    width: 100%;
    border: solid 2px #242D3D;
    max-width: 5rem;
}

.search-result-event .search-event-date-month {
    background-color: #242D3D;
    color: white;
    font-size: x-large;
    font-weight: 600;
}

.search-result-event .search-event-date-day {
    font-size: xx-large;
    font-weight: 600;
    height: 4rem;
}

/** Results - Twitter Cards */

.search-result-twitter-tweet {
    min-height: 4.5rem;
    max-height: 4.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

/** Contextual Navigation */

.search-contextual-navigation h3 {
    color: #242D3D;
}

/** Curator */

blockquote.search-exhibit {
    border-left: solid .25rem #eeefea;
}

/** Open Day Exhibit */

.search-exhibit.open-day {
    background-color: #fff;
}

.search-exhibit.open-day.card {
    border: none;
}

.search-exhibit.open-day .card-header {
    background-color: #242D3D;
    padding: .75rem 1.25rem .25rem 1.25rem;
}

.search-exhibit.open-day .card-header a {
    font-size: x-large;
    font-weight: 600;
    color: white;
}

.search-exhibit.open-day .btn-primary {
    background-color: #242D3D;
    border: none;
    margin-top: 1rem;
}

.search-exhibit.open-day .card-body {
    padding-top: 0;
    font-size: x-large;
    font-weight: 600;
}

.search-exhibit.open-day .card-body div {
    padding-top: .5rem;
    padding-bottom: 1rem;
}

.search-exhibit.open-day small {
    color: #333;
    font-size: medium;
    display: block;
}

/** Campus Life Exhibit */

.search-exhibit.campus-life .card-header {
    display: none;
}

.search-exhibit.campus-life .card-body {
    padding: 0;
}

.search-exhibit.campus-life .card-body a {
    color: white;
    text-shadow: 2px 2px #333;
    font-size: x-large;
    font-weight: 600;
}

/** Paging */

.notice a {
    color: #35659e;
    text-decoration: underline;
}

.pagination-lg .page-item:last-child .page-link,
.pagination-lg .page-item:first-child .page-link {
    white-space: nowrap;
}

@media (max-width: $tablet) {
    .pagination-lg .page-link {
        padding: .5rem .75rem;
        font-size: 1.25rem;
    }
}
@media (max-width: $desktop) {
    body {
        background: #fff!important;
    }
} 