// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #4a4a4a;

// Font Suits
$font-family-noto-serif: 'Noto Serif', Georgia, 'Times New Roman', serif;
$font-family-source-sans-pro: 'Source Sans Pro', 'Gill Sans', 'Trebuchet MS', Arial, Sans-serif;
$font-family-fraunces: 'Fraunces', serif;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

$primary-blue: #084183;

// Default breakpoints
$tablet: 768px;
$desktop: 1200px;

// Colours

$bg-white: #f2f5f9;

$grey-200: #F7F7F7;
$grey-300: #e6e6e6;
$grey-500: #696D73;
$grey-600: #616161;
$grey-800: #1d1d1d;

$blue-200: #cee5ff;
$blue-300: #4074b2;
$blue-400: #084183;
$blue-600: #042a58;

$blue-mid-1: #ADCDF0;
$blue-mid-2: #4074B2;

$teal-mid-1: #7CBDCE;
$teal-mid-2: #3FA7C2;

$emerald-mid-1: #98D6C3;
$emerald-mid-2: #59B17F;

$emerald-dark: #22765F;

$green-mid-1: #DAE299;
$green-mid-2: #A1D172;
$green-dark: #467749;

$purple-mid-1: #B0BCE1;
$purple-mid-2: #7283BF;
$purple-dark: #5760A4;

$orange-mid-2: #E77052;

$grey-mid-0: #D1D6D9;
$grey-mid-1: #696d73;
$grey-mid-2: #4a4a4a;
$grey-mid-3: #1D1D1D;

$navy-mid-1: #ABBED5;
$navy-mid-2: #5D82AD;
$navy-dark: #094183;

$grey-light: #edf2f5;
$grey-light-hover: #d1d6d9;
$navy-dark-hover: #012a58;

$background-secondary-navy: #033A7B;

$brand-light-2: #DAE3ED;