/*
 * Mobile layout
 */

    // .pagination-lg .page-item:first-child .page-link {
    //     border-radius: 0;
    // }

    .pagination {
        margin-top: 35px;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        


        .page-item {
            margin: 0;
            max-width: none;
            width: auto;
            padding-block-end: 0;
        }

        .page-link {
            text-decoration: none;
            padding-inline: 0.75rem;
        }

        //had to increase specificity to overwrite existing design
        &.pagination-lg {
            margin-inline-start: 1.25rem;
            margin-inline-end: 0;
            padding: 0;
            max-width: none;

            a.page-link {
                &:not(:is(.page-link--next, .page-link--previous)) {
                    border-radius: 0;
                }
            }
        }

        a.page-link {
            font-size: var(--fs-level-6);
            font-weight: var(--fw-semibold);
            line-height: var(--lh-140);
            letter-spacing: var(--ls-1);
            text-align: center;
            color: var(--col-btn-secondary-text);
            // padding: 13px 19px;
            // border: 1px solid $blue-mid-2;
            padding: 0.312rem 0.75rem;
            border: none;
            border-radius: 0;
            background-color: transparent;

            &:is(:hover, :focus) {                
                text-decoration: underline;
            }

            

            &:where(.page-link--next, .page-link--previous) {
                background-color: var(--col-btn-secondary-bg);
                border-radius: 0.3rem;
                padding: 0.75rem 1rem;
                font-weight: var(--fw-bold);

                &:is(:hover, :focus) {
                    color: var(--col-btn-secondary-text-hover);
                    background-color: var(--col-btn-secondary-bg-hover);
                    text-decoration: none;
                }
            }

            &--next {
                margin-inline-start: 1.75rem;
            }

            &--previous {
                margin-inline-end: 1.75rem;
            }
        }       

        li.page-item.active {
       
            a.page-link {
                background-color: var(--col-sage-light-25);
                color: var(--col-btn-secondary-text);
                font-weight: var(--fw-regular);
                

                &:is(:hover, :focus) {
                    text-decoration: none;
                }
            }
        } 

        &-nav {
            height: 12px;

            &--right {
                content: url("~src/images/chevron-navy-right.svg");
                padding-left: 7px;
            }

            &--left {
                content: url("~src/images/chevron-navy-left.svg");
                padding-right: 7px;

            }
        }

        &--desktop {
            display: none;
        }
    }

/*
 * Tablet layout
 */

 @media screen and (min-width: $tablet){
    .pagination {
        justify-content: flex-start;

        &--desktop {
            display: flex;
        }

        &--mobile {
            display: none;
        }
    }
}

/*
 * Desktop layout
 */

@media screen and (min-width: $desktop){
    .pagination {
        margin-top: 40px;
        margin-left: 1.25rem;
        margin-bottom: 0;

        li.page-item {
            margin-bottom: 0;
        }
    }
}
